require('../../node_modules/uikit/dist/js/uikit.min.js');
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.slider('.uk-slider', {autoplay:true});
$(document).on('click', ".mobile-menu-icon", function ( event ) {
    if($(this).find('input').is(':checked')) {
        $('.menu-container').addClass('open');
    } else {
        $('.menu-container').removeClass('open');
    }
} )



$(window).scroll(function() {
    var scroll = $(window).scrollTop();
    if (scroll >= 1) {
        $(".page-home-ebike header").addClass("headerbg");
    }
    if($(window).scrollTop() === 0) {
        $(".page-home-ebike header").removeClass("headerbg");
    }
});





jQuery(window.document).ready(function () {
    $('select').change(function(){
        if($(this).val() == '60 Ah – tot 140 km ( + 699 euro)' || $(this).val() == '60 Ah – tot 280 km ( + 699 euro)'){
            $('.product-stock-notification').text('De geschatte levertermijn bedraagt 3 weken.');
        }
        else {
            $('.product-stock-notification').text('De geschatte levertermijn bedraagt 3 weken.');
        }
    });

/*    $('.cart-content-row div:contains("30 Ah")').each(function() {
        $(this).closest('.cart-content-row').find('.stock-notification > div').text('De geschatte levertermijn bedraagt 2 weken.');
    })
    $('.cart-content-row div:contains("60 Ah")').each(function() {
        $(this).closest('.cart-content-row').find('.stock-notification > div').text('De geschatte levertermijn bedraagt 2 weken.');
    })*/

});
